import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import '../../Views/AuthViews/login.css';

import { Box, InputLabel, Link } from '@material-ui/core';
import auth from '../../Services/auth';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '32px',
      gap: '32px',
      width: '100%',
      height: "70%",
      backgroundColor: "white"
    },
  
  
    form: {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      fontStyle:'italic', 
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing:'0em',
      textAlign: 'left',
      color: '#000000',
      width:'343px',

    },
    submit: {
       flex :'none',
    backgroundColor:' #EC6730',
    color :' #FFFFFF',
    height:'61.73px',
    width:'343px',
    fontSize:'17px',
    fontFamily: 'OpneSans',
    textAlign: 'center',
    letterSpacing: '0.08em',
    borderRadius:'2px',

    },
    label :{
      width:'343px',
      height:'40px',
      fontFamily: 'Open-Sans',
      fontSize: '12px',
      fontStyle:'italic', 
      fontWeight: '400',
      lineHeight: '20.43px',
      letterSpacing:'0em',
      textAlign: 'center',
      color :' #A79C87',
      align:'center',
    },
    title :{
      lineHeight:'20px',
      fontStyle: 'normal',
      fontWeight:'600',
      fontSize:'16px',
      fontFamily: 'Opne-Sans',
      letterSpacing: '0.08em',
      color:'#00000',
      width:'343px',
      height:'24px',textAlign:'center',alignSelf:'stretch',
      lettre:'8%',
    },
  }));
   export default function Reset()
    {
    const classes = useStyles();
    const [email, setEmail] = useState();
    const [user_type, setUser] = useState('admin');
    const navigate = useNavigate();

    const [values, setValues] = useState({
        email: '',
    });

    const handleSubmit = async e => {
    
      e.preventDefault();
      const response = await auth.Reset({
        email,       
        user_type      });
        console.log(response.message);
        console.log(localStorage.setItem("email", email))

      if (response.message =="e-mail envoyé avec succès") {
        navigate('/confirmation', {state:email})
      //    window.location.href = "/confirmation";
  
      } else {
        console.log("erreur")
      } 
     
    }

    return (       

    
      <Grid
      container
      spacing={0}
      align="center"
      justify="center"
      direction="column"
      
    >
      <Grid item >
        <Grid item className={classes.paper}>
            <Typography justifyContent='center' className={classes.title}>
              {'mot de passe oublié'.toUpperCase()}
            </Typography>
            <InputLabel className={classes.label}>
            Veuillez entrer votre adresse e-mail pour<br></br>     réinitialiser votre mot de passe </InputLabel>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="Identifiant"
                name="Identifiant"
                label="Identifiant"
                type="email"
                onChange={e => setEmail(e.target.value)}
              />
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
                size="large"
                value={email}
              >
                {'réinisialiser'.toUpperCase()}  

              </Button>
            </form>
          </Grid>
        </Grid>
      </Grid>
    );
  }



