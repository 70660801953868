import {env} from "../config/env";

const token = localStorage.getItem('access_token');



async function chageStatut(credentials) {
  return fetch(env.backendUrl + 'changeStatusParticipation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization : ` Bearer ${token}`
    },
    body: JSON.stringify(credentials)
  })
  .then((response) => response.json())
  .then((actualData) => {
    console.log(actualData);
  },
  );
}

export default {chageStatut} ;
