import React from "react";
import "./modalSupression.css";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function ModalSuppression({ setOpenModal,setModalOpen}) {
  return (
  <div className="modal1 display-block" onClick={() => {
    setModalOpen(false);
    setOpenModal=true
    
  }}>
      <section className="modal-main"  onClick={e => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}  >
        <div className="titleCloseBtn">
          <button
            onClick={() => {

              setModalOpen(false);
              setOpenModal=true

            }}
          >
            X
          </button>
        </div>
        <div className="title t3">
          <h1><ErrorOutlineIcon style={{ color: 'rgba(242, 185, 87, 1)',fontSize: "80px",marginLeft:'37%',marginTop:'-20%'}} ></ErrorOutlineIcon></h1>
        </div>
        <div className="body b1">
          <h2>Supprimer le compte</h2>
        </div>
        <div className="body b1">
        <p>Voulez-vous vraiment supprimer le compte ?</p>
        </div>
            
        <div className="footer1">
          <button
            onClick={() => {
              setModalOpen(false);
            }}
            id="cancelBtn2"
          >
            Annuler
          </button>
          <button >Supprimer</button>
        </div>
        </section>
    </div>
  );
}

export default ModalSuppression;
