import {systemConfig} from "./systemConfig";

export const usersConfig = {
    chiefStatus: {
        chief: 'normal',
        chiefMedal: 'top,medaille',
        clients: ''
    },
    usersLabels: { // todo: these items should be rewrite with a translate service
        chief: 'Chef',
        chiefMedal: 'Chef prestigieux',
        clients: 'Clients',
    },
    usersType: {
        chief: 'chief',
        chiefMedal: 'chiefMedal',
        clients: 'clients',
    },
    statusAccount: {
        values: {
            waiting: 'en_cours',
            active: 'actif',
            disable: 'deleted'
        },
        labels: {
            waiting: 'En attente',
            active: 'Actif',
            disable: 'Supprimé'
        }
    },
    clubStatus: {
        values: {
            active: 1,
            disabled: 2
        },
        labels: {
            active: 'Activé',
            disabled: 'Désactivé'
        }
    }
}

export const optionsChiefStatusFilter = [
    {
        value: null,
        label: 'Statut du compte',
        color: null
    },
    {
        value: usersConfig.statusAccount.values.waiting,
        label: usersConfig.statusAccount.labels.waiting,
        color: systemConfig.color.orange
    },
    {
        value: usersConfig.statusAccount.values.active,
        label: usersConfig.statusAccount.labels.active,
        color: systemConfig.color.green
    },
]


export const optionsUsersStatusFilter = [
    {
        value: null,
        label: 'Compte',
        color: null
    },
    {
        value: usersConfig.statusAccount.values.waiting,
        label: usersConfig.statusAccount.labels.waiting,
        color: systemConfig.color.orange
    },
    {
        value: usersConfig.statusAccount.values.active,
        label: usersConfig.statusAccount.labels.active,
        color: systemConfig.color.green
    },
    {
        value: usersConfig.statusAccount.values.disable,
        label: usersConfig.statusAccount.labels.disable,
        color: systemConfig.color.red
    },
]
export const optionsClubStatusFilter = [
    {
        value: null,
        label: 'Club privilège',
        color: null
    },
    {
        value: usersConfig.clubStatus.values.active,
        label: usersConfig.clubStatus.labels.active,
        color: systemConfig.color.green
    },
    {
        value: usersConfig.clubStatus.values.disabled,
        label: usersConfig.clubStatus.labels.disabled,
        color: systemConfig.color.red
    },
]

export const columnsData = [
    {
        type: usersConfig.usersType.chief,
        columns: ['Nom', 'Prénom', 'Status', 'Notes']

    },
    {
        type: usersConfig.usersType.chiefMedal,
        columns: ['Nom', 'Prénom', 'Type', 'Status']

    },
    {
        type: usersConfig.usersType.clients,
        columns: ['Nom', 'Prénom', 'Clé d\'authentification', 'Date d\'inscription', 'Status']

    },
]
