import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/messaging'
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD7Pgr2FnVMcG18grTgUTzrUVQYAv1suIc",
  authDomain: "gastronhome-d0332.firebaseapp.com",
  projectId: "gastronhome-d0332",
  storageBucket: "gastronhome-d0332.appspot.com",
  messagingSenderId: "656781276437",
  appId: "1:656781276437:web:43f252fc99bc3620dbe51c",
  measurementId: "G-PN75E1J04E"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
  export const firestore = firebase.firestore();


  export default firebase;