import React, {useEffect, useState} from "react";
import {getUsersStatistics} from "../../Services/dashboard";
import type {UsersStatisticsType} from "../../utils/types/UsersStatisticsTypes";
import DoughnutChart from "./doughnutChart";
import {systemConfig} from "../../config/systemConfig";

const usersLabels = [
    {key: 'count_chefs_normal', value: 'Chefs'},
    {key: 'count_clients', value: 'Clients'}
]

const globalStatistics = {
    key: 'Total des chefs',
    value: 0
}
const initialChartData = {
    labels: [],
    dataSetsLabel: 'Utilisateurs GetChef',
    dataSetsData: []
}
const UsersStatistics = () => {
    const [chartData, setChartData] = useState(initialChartData);
    const [global, setGlobal] = useState(globalStatistics);
    const getData = () => {
        return getUsersStatistics();
    }
    useEffect(() => {
        getData().then((response: UsersStatisticsType) => {
            if (response && response.code && response.code === 'succes') {
                const myLabels = []
                const myData = []
                usersLabels.forEach((item) => {
                    myLabels.push(item.value)
                    myData.push(response[item.key])
                })
                setGlobal({
                    ...global,
                    value: response.count_all_chefs
                })
                setChartData({
                    ...chartData,
                    labels: myLabels,
                    dataSetsData: myData
                })
            }
        })
    }, [])

    return (
        <div className="col d-flex justify-content-around">
            <div className="card border-0 p-md-3">
                <div className="m-lg-4 ">
                    {global.key} <span
                        style={{color: systemConfig.color.green}}>{global.value}</span>
                </div>
                {(chartData.labels.length > 0) &&
                <DoughnutChart data={chartData}/>
                }
            </div>
        </div>
    )
}

export default UsersStatistics;
