import React from "react";
import ReactPaginate from 'react-paginate';
import {hover} from "@testing-library/user-event/dist/hover";

export const CustomPagination: React.FunctionComponent<any>
    = (props: {currentPage: number, countPage: number, handleCurrentPage: () => void}) => {

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        props.handleCurrentPage(event.selected);
    };
    return (
        <div className="mt-lg-3 customPagination">
            <ReactPaginate
                breakLabel="..."
                nextLabel=">>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={props.countPage}
                previousLabel="<<"
                renderOnZeroPageCount={null}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
            />
        </div>
    )
}
