import React from 'react';

export const InfoLine = (props: {
    label: string,
    value?: string,
    tabData?: string
}) => {
    return (
        <div className="mb-3">
            <p className="card-title">{props.label}</p>
            {props.value && <h5 className="card-text">{props.value}</h5>}
            {props.tabData && <h5 className="card-text">{props.tabData}</h5>}
        </div>
    );
}