import React, {useState} from 'react';
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import {env} from "../../config/env";
import {NotificationManager} from "react-notifications";
import {systemConfig} from "../../config/systemConfig";

// todo: this should be refactored
export const TokenKeyGenerator = (props: {
    clientId: number,
}) => {
    const token = localStorage.getItem('access_token');
    const [randomKey, setRandomKey] = useState('')
    const [endDate, setEndDate] = useState((new Date()).toLocaleDateString(systemConfig.dateFormat))
    const [isGeneratorDisplayed, setIsGeneratorDisplayed] = useState(true)
    const endDateHandler = (event) => {
        setEndDate(event.target.value)
    }
    const generateVIPClientKey = () => {
        setVPClient({
            client_id: props.clientId,
            start_date: (new Date()).toLocaleDateString(systemConfig.dateFormat),
            end_date: (new Date(endDate)).toLocaleDateString(systemConfig.dateFormat)
        }).then()
    }

    async function setVPClient(credentials) {
        const response = await fetch(env.backendUrl + 'setVPClinet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: ` Bearer ${token}`
            },
            body: JSON.stringify(credentials)
        });
        if (!response.ok) {
            NotificationManager.error(response.statusText, 'Erreur ' + response.status)
        } else {
            const data = await response.json();
            if (data && data.vp_token) {
                setRandomKey(data.vp_token)
                NotificationManager.success(data.message, 'Succès')
            }
        }
    }

    const hideGenerator = () => {
        setIsGeneratorDisplayed(!isGeneratorDisplayed)
    }

    return (
        <>
            <div className="border-bottom mb-5 pb-4">
                <div className="d-flex justify-content-between">
                    <h6>Club privilège activé</h6>
                    <BootstrapSwitchButton checked={isGeneratorDisplayed}
                                           onstyle="success"
                                           onlabel='Oui'
                                           offlabel='Non'
                                           onChange={hideGenerator}
                    />
                </div>
                {isGeneratorDisplayed && <>
                    <div className="fake-link" onClick={generateVIPClientKey}>
                        Générer automatiquement
                    </div>
                    <div className="d-flex mb-3 justify-content-between">
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Clé d'authentification"
                            className="col-5"
                        >
                            <Form.Control type="text" placeholder="Clé" value={randomKey}/>
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingPassword" label="Date d'abonnement"
                                       className="col-5">
                            <Form.Control type="date" value={endDate} onChange={endDateHandler}/>
                        </FloatingLabel>
                    </div>
                </>
                }
            </div>

        </>
    );
}

