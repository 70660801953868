import {systemConfig} from "./systemConfig";

export const statusConfig = {
    statusValues: {
        inProgress: 'en_cours',
        accepted: 'valide',
        rejected: 'refuse',
        draft: 'brouillon',
        treaty: 'actif',
        waiting: 'en_attente',
    },
    statusLabels: { // todo: these items should be rewrite with a translate service
        inProgress: 'En attente',
        accepted: 'Validé',
        rejected: 'Refusé',
        draft: 'Brouillon',
        treaty: 'Traité', // events case
        waiting: 'En attente', // events case
    },
    statusColors: {
        inProgress: systemConfig.color.orange,
        accepted: systemConfig.color.green,
        rejected: systemConfig.color.red,
        draft: systemConfig.color.gray,
        treaty: systemConfig.color.green, // events case
        waiting: systemConfig.color.orange, // events case
    },
    possibleActions: {
        menu: {
            validate: 'activeMenu',
            refuse: ''
        }
    }
}
export const statusControls = [
    {
        status: statusConfig.statusValues.inProgress,
        label: statusConfig.statusLabels.inProgress,
        color: statusConfig.statusColors.inProgress,
        possibleActions: []
    },
    {
        status: statusConfig.statusValues.accepted,
        label: statusConfig.statusLabels.accepted,
        color: statusConfig.statusColors.accepted
    },
    {
        status: statusConfig.statusValues.rejected,
        label: statusConfig.statusLabels.rejected,
        color: statusConfig.statusColors.rejected
    },
    {
        status: statusConfig.statusValues.draft,
        label: statusConfig.statusLabels.draft,
        color: statusConfig.statusColors.draft
    },
    {
        status: statusConfig.statusValues.treaty,
        label: statusConfig.statusLabels.treaty,
        color: statusConfig.statusColors.treaty
    },
    {
        status: statusConfig.statusValues.waiting,
        label: statusConfig.statusLabels.waiting,
        color: statusConfig.statusColors.waiting
    }
]
