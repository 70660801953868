import React from 'react';

export const MinimumProfile = (props: {
    avatar: string,
    firstName: string,
    lastName: string,
}) => {

    return (
        <div className="d-flex mb-5 pb-2 border-bottom">
            {(props.avatar !== null) &&
            <div className="image-content avatar bg-secondary">
                <img src={props.avatar}
                     alt={props.firstName}
                />
            </div>}
            <div className="m-lg-2">
                <h5 className="mt-4">{props.firstName} <strong className="ml-1">{props.lastName}</strong></h5>
            </div>
        </div>
    );
}

