import React from "react";
// this import is added to fix error compilation related to charts ==> should not be removed
import {Chart as ChartJS, layouts} from 'chart.js/auto'
import {Bar} from "react-chartjs-2";
import {systemConfig} from "../../config/systemConfig";


const myBarChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
}

const BarChart = (props) => {
    const chartData = {
        dataBar: {
            labels: props.data.labels,
            datasets: [
                {
                    label: props.data.dataSetsLabel,
                    data: props.data.dataSetsData,
                    backgroundColor: systemConfig.color.orange,
                    maxBarThickness: 25,
                }
            ]
        }
    }
    return (
        <Bar type='bar' data={chartData.dataBar} options={myBarChartOptions}/>
    )
}

export default BarChart;
