import React from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

export const DisableActiveUser = (props: {
    checked: boolean,
    handleStatusChangeEvent: () => void,
    handleDelete?: () => void,
}) => {
    const handleStatusChangeEvent = (event) => {
        props.handleStatusChangeEvent(event)
    }
    return (
        <div className="border-bottom mb-3 pb-4">
            {/* <div className="d-flex justify-content-between">
                <h6>Compte activé</h6>
                <BootstrapSwitchButton checked={props.checked}
                                       onstyle="success"
                                       onlabel='Oui'
                                       offlabel='Non'
                                       onChange={(checked: boolean) => {
                                           handleStatusChangeEvent({status: checked})
                                       }}
                />
            </div> */}
            <div>

                    <span className="text-danger" style={{'cursor':'pointer'}}
                          onClick={props.handleDelete}
                    >Supprimer le compte</span>
            </div>
        </div>
    );
}

