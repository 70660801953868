import React, {useState} from 'react'
import SearchIcon from "@mui/icons-material/Search";


const SearchInput = (props: {
    placeholder: string,
    handleChange: () => void
}) => {

    const [searchInput, setSearchInput] = useState('');

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        props.handleChange(e.target.value)
    };

    return (
        <div className="form-group has-search">
            <SearchIcon className="form-control-feedback" sx={{ fontSize: 20 }} />
            <input type="text"
                   className="form-control"
                   placeholder={props.placeholder}
                   onChange={handleChange}
                   value={searchInput}/>
        </div>)


};

export default SearchInput;
