import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import {NotificationManager} from "react-notifications";
import users from "../../Services/users";

const initialPassword = {
    password: '',
    password_confirmation: '',
}
export const ChangePasswordModal = (props: {
    show: boolean,
    handleClose: () => void,
}) => {
    const [myPassword, setMyPassword] = useState(initialPassword)
    const updatePassword = async () => {
        return await users.updatePassword({
            password_confirmation: myPassword.password_confirmation,
            password: myPassword.password
        });
    }
    const handleAction = () => {
        if (myPassword.password !== myPassword.password) {
            NotificationManager.error('Veuillez vérifier le mot de passe!')
        } else {
            updatePassword().then((response) => {
                if(response && response.error) {
                    NotificationManager.error('Une erreur est produite! Veuillez réessayer.')
                } else {
                    props.handleClose()
                    NotificationManager.success('Le nouveau mot de passe est bien enregsitré!')
                }
            })
        } 
    }
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title><span className="my-color">Changer le mot de passe</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Nouveau mot de passe</Form.Label>
                        <Form.Control type={'password'}
                                      onChange={(event) => {
                                          if (event.target.value.length === 1) {
                                              NotificationManager.info('Le mot de passe doit être composé d\'au moins 6 caractères!');
                                          } else if (event.target.value.length > 5) {
                                              setMyPassword({
                                                  ...myPassword,
                                                  password: event.target.value
                                              })
                                          }
                                      }}/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Confirmer le mot de passe</Form.Label>
                        <Form.Control type={'password'}
                                      onChange={(event) => {
                                          setMyPassword({
                                              ...myPassword,
                                              password_confirmation: event.target.value
                                          })
                                      }}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Fermer
                    </Button>
                    <Button
                        variant="success"
                        disabled={myPassword.password === '' || myPassword.password_confirmation === ''}
                        onClick={handleAction}>Valider</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

