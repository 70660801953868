import React from 'react';
import type {UserDetails} from "../../utils/types/UsersTypes";
import {UserPersonalData} from "./UserPersonalData";
import {usersConfig} from "../../config/usersConfig";
import {DisableActiveUser} from "./DisableActiveUser";
import {TokenKeyGenerator} from "./TokenKeyGenerator";
import {InfoLine} from "../../components/reusables/InfoLine";
import {systemConfig} from "../../config/systemConfig";
import Carousel from "../../components/reusables/carousel/Carousel";

export const ModalUserContent = (props: {
    data: UserDetails,
    dataType: string,
    handleStatusChange: () => void,
    handleDelete?: () => void,
}) => {
    const handleStatusChangeEvent = (event) => {
        console.log('handleStatusChange event = ', event);
        props.handleStatusChange(event.status)
    }
    const deleteChef = () => {
        props.handleDelete(props.data.id);
    }
    return (
        <>
            {
                (props.dataType === usersConfig.usersType.clients) &&
                <>
                    <InfoLine label={"Date d'inscription"}
                              value={(new Date(props.data.created_at)).toLocaleDateString(systemConfig.dateFormat)}/>
                    <br/>
                </>
            }
            {
                (props.data.statut_compte === usersConfig.statusAccount.values.active
                    || props.dataType === usersConfig.usersType.clients) &&
                <DisableActiveUser handleStatusChangeEvent={handleStatusChangeEvent}
                                   handleDelete={deleteChef}
                                   checked={(props.dataType === usersConfig.usersType.clients) ?
                                       (props.data.vp_token !== null) : true
                                   }
                />
            }
            {
                (props.data.cv_chef && props.data.cv_chef != '') && <div className={'mb-4'}>
                    Voir le CV par <a href={props.data.cv_chef} className={'text-primary'} target={'_blank'}>ici </a>!
                </div>
            }
            {
                (props.dataType === usersConfig.usersType.clients) &&
                <TokenKeyGenerator clientId={props.data.id}/>
            }
            <UserPersonalData data={props.data} userType={props.dataType} />
            {(props.data.images && props.data.images.length > 0) && <Carousel
                show={2}
            >
                {props.data.images.map((img) => {
                    return (<div>
                        <div style={{padding: 8}}>
                            <img src={img} alt="placeholder" style={{maxWidth: '100%'}}/>
                        </div>
                    </div>)
                })
                }

            </Carousel>}
        </>
    );
}

