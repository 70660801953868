import React from "react";
import {AppBar, Toolbar} from "@mui/material";
import logo from '../../images/logo.png'


const AppBarLogin = () =>{

  const toolbarStyle = {
    minHeight: '2px',
    backgroundColor: 'white',
    boxShadow: "none",width:'100%',height:'70px',padding:'0px 32px',
  };

    return(

            <AppBar  elevation={0} position='absolute'  >
                <Toolbar style={toolbarStyle}>
                <img src={logo} alt="logo" style={{maxWidth: 200}}  />
                </Toolbar>
            </AppBar>
    )
}



export default AppBarLogin
