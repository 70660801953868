import React from "react";
// this import is added to fix error compilation related to charts ==> should not be removed
import {Chart as ChartJS, layouts} from 'chart.js/auto'
import {Doughnut} from "react-chartjs-2";

const myChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
}

const DoughnutChart = (props) => {
    const chartData = {
        dataBar: {
            labels: props.data.labels,
            datasets: [
                {
                    label: props.data.dataSetsLabel,
                    data: props.data.dataSetsData,
                    backgroundColor: [
                        'rgba(246, 125, 12, 1)',
                        'rgba(246, 125, 12, 0.6)',
                    ],
                    hoverOffset: 4
                }
            ]
        }
    }
    return (
        <Doughnut data={chartData.dataBar} options={myChartOptions}/>
    )
}

export default DoughnutChart;
