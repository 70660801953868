import {env} from "../config/env";

const token = localStorage.getItem('access_token');

const MenusList = () => {
    fetch(env.gitHubUrl + 'users')
        .then((res) => res.json())
        .then((res) => {
            console.log(res)
        })

}

async function validMenu(credentials) {
    console.log('body == ', JSON.stringify(credentials))
    return fetch(env.backendUrl + 'activeMenu', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: ` Bearer ${token}`
        },
        body: JSON.stringify(credentials)
    })
        .then((response) => response.json())
        .then((actualData) => {
                console.log(actualData);
            },
        );
}

async function getEventById(credentials) {
    return fetch(env.backendUrl + 'getListParticipations/all/id', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: ` Bearer ${token}`
        },
        body: JSON.stringify(credentials)
    })
        .then((response) => response.json())
        .then((actualData) => {
                console.log(actualData);
            },
        );
}
export default {MenusList, validMenu, getEventById, };
export async function refuseMenu(credentials) {
    await fetch(env.backendUrl + 'refuseMenu', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization : ` Bearer ${token}`
        },
        body: JSON.stringify(credentials)
    })
        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        })
        .catch(error => {
            console.error('There was an error!', error);
            return Promise.reject(error);
        });
}
