import React from "react";
import {InfoLine} from "../../components/reusables/InfoLine";
import {Rating} from "../../components/reusables/Rating";
import {usersConfig} from "../../config/usersConfig";

export function UserPersonalData(props: { data: any, userType: string }) {
    return <div className="card mb-2">
        <div className="card-body">
            <InfoLine label={"Id"} value={props.data.id}/>
            <InfoLine label={"Nom et Prénom"} value={props.data.nom + " " + props.data.prenom}/>
            <InfoLine label={"Email"} value={props.data.email}/>
            <InfoLine label={"Téléphone"} value={props.data.phone}/>
            {(props.data.ville && props.userType !== usersConfig.usersType.clients) &&
            <InfoLine label={"Adresse"} value={props.data.ville}/>}
            {(props.data.address && props.userType === usersConfig.usersType.clients) &&
            <InfoLine label={"Adresse"} value={props.data.address}/>}
            {(props.userType !== usersConfig.usersType.clients) && <>
                <InfoLine label={"Numéro de SIREN"} value={props.data.siren}/>
                {props.data.annees_profession &&
                <InfoLine label={"Année de profession"} value={props.data.annees_profession}/>}
                <InfoLine label={"Expérience à l'étranger"}
                          value={(props.data.experience_etranger === 1) ? "Oui" : "Non"}/>

                {(props.data.langages !== null) &&
                <InfoLine label={'Langues'} tabData={props.data.langages}/>
                }
            </>}
            {(props.data.noter && props.data.noter !== null) &&
            <Rating rating={props.data.noter}/>}

        </div>
    </div>;
}
