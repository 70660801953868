import { Visibility, VisibilityOff } from "@material-ui/icons";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Alert, Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Snackbar, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import "./modalAddChef.css";
import users from "../../../Services/users";
import {useState} from "react"
import swal from 'sweetalert';

function ModalAddChef({ details,setOpenModal,childToParentBoolean }) {

  //console.log(details);
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  
  const [statut_chef, setTypeChef] = React.useState('');
  const [alignment, setAlignment] = React.useState('Monsieur');
  const handleChangeUser = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleChangetype = (event) => {
    setTypeChef(event.target.value);
  };

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [nom, setNom] = useState();
  const [prenom, setPrenom] = useState();
  const [phone, setPhone] = useState();
  const [ville, setVille] = useState();
  const [siren, setSiren] = useState();
  const [password_confirmation, setPassword_conf] = useState();

  const handleSubmit = async e => {
   // console.log('hello1');
    e.preventDefault();
    const response = await users.addChef({
      nom,
      prenom,
      email,
      phone,
      ville,
      siren,
      statut_chef:statut_chef.toString(),
      password_confirmation,
      password,
    });
    /*console.log('hello2');
    console.log(response)*/
    if ('chef' in response) {
      //console.log('hello3');
      childToParentBoolean(true);
     /* swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {*/
          handleClick();
          //window.location.href = "/utilisateurs";
       // });
    } else {
      console.log('hello4');
      console.log(response.message);
      console.log(statut_chef.type);
      //swal("Failed","","error"
      //).then((value) => {
        //handleClick();
        childToParentBoolean(false);
        //window.location.href = "/utilisateurs";
      //});
    }
  }

  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "rgba(167, 156, 135, 1)",
        borderRadius:0
      },
      borderRadius:0
    },
  }

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showPasswordConf, setShowPasswordConf] = React.useState(false);
  const handleClickshowPasswordConf = () => setShowPasswordConf(!showPasswordConf);
  const handleMouseDownPasswordConf = () => setShowPasswordConf(!showPasswordConf);



  const handleChangeMessage = event => {
    setPassword(event.target.value);
    console.log('value is:', event.target.value);
  };

  const handleChangeMessageConf = event => {
    setPassword_conf(event.target.value);
    console.log('value is:', event.target.value);
  };


  const handleClick = () => {
    setOpenModal(false)
  };



  return (
    
    <div className="modal display-block" style={{overflowY: "auto"}} onClick={() => {
      setOpenModal(false);
    }}>
    <div className="block-m4" onClick={e => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }} >
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>

        <div className="title t1">
            Nouveau Chef

        </div>

        <ToggleButtonGroup sx={{'& > :not(style)': { mt: 5,ml:2, width: '34ch',height: '35px'}}}
         value={alignment} exclusive onChange={handleChangeUser}>
          <ToggleButton value="Madame" style={{textTransform: "none",borderRadius:0}}>Madame</ToggleButton>
          <ToggleButton value="Monsieur" style={{textTransform: "none",borderRadius:0}}>Monsieur</ToggleButton>
        </ToggleButtonGroup>

        <form noValidate onSubmit={handleSubmit}>

        <Box component="form" sx={{'& > :not(style)': { mt: 3,ml:2, width: '66ch',height:'5ch' },}}
          noValidate autoComplete="off">
          <TextField id="outlined-basic" label="Nom" variant="outlined" 
          InputLabelProps={{style:{fontStyle:'italic' ,color : '#A79C87',fontSize:'15px'} }}
          sx={style}
          onChange={e => setNom(e.target.value)} 
          />
        </Box>

        <Box component="form" sx={{'& > :not(style)': { mt: 3,ml:2, width: '66ch',height:'5ch' },}}
          noValidate autoComplete="off">
          <TextField id="outlined-basic" label="Prénom" variant="outlined" 
          InputLabelProps={{style:{fontStyle:'italic' ,color : '#A79C87',fontSize:'15px'} }}
          sx={style} 
          onChange={e => setPrenom(e.target.value)}
          />
        </Box>

        <Box component="form" sx={{'& > :not(style)': { mt: 3,ml:2, width: '66ch',height:'5ch' },}}
          noValidate autoComplete="off">
          <TextField id="outlined-basic" label="E-mail" variant="outlined" 
          InputLabelProps={{style:{fontStyle:'italic' ,color : '#A79C87',fontSize:'15px'} }}
          sx={style} 
          onChange={e => setEmail(e.target.value)}
          />
        </Box>

        <Box component="form" sx={{'& > :not(style)': { mt: 3,ml:2, width: '66ch',height:'5ch' },}}
          noValidate autoComplete="off">
          <TextField id="outlined-basic" label="Téléphone" variant="outlined" 
          InputLabelProps={{style:{fontStyle:'italic' ,color : '#A79C87',fontSize:'15px'} }}
          sx={style} 
          onChange={e => setPhone(e.target.value)}
          />
        </Box>

        {<FormControl sx={{'& > :not(style)': { mt: 3,ml:2, width: '59.5ch',height:'6ch' },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "rgba(167, 156, 135, 1)"
            }
          },
          }} >
          <InputLabel id="demo-simple-select-label" style={{fontStyle:'italic' ,color : '#A79C87',fontSize:'15px'}}
          >Type de Chef</InputLabel>
          <Select labelId="demo-simple-select-label" id="demo-simple-select"value={statut_chef} label="Type de Chef"
          onChange={handleChangetype}  >
            <MenuItem value={'top'}>top</MenuItem>
            <MenuItem value={'etoile'}>etoile</MenuItem>
            <MenuItem value={'medaille'}>medaille</MenuItem>
          </Select>
        </FormControl>}

        <Box component="form" sx={{'& > :not(style)': { mt: 1,ml:2, width: '66ch',height:'5ch' },}}
          noValidate autoComplete="off">
          <TextField id="outlined-basic" label="Code Siren" variant="outlined" 
          InputLabelProps={{style:{fontStyle:'italic' ,color : '#A79C87',fontSize:'15px'} }}
          sx={style} 
          onChange={e => setSiren(e.target.value)}/>
        </Box>

        <Box component="form" sx={{'& > :not(style)': { mt: 3,ml:2, width: '66ch',height:'5ch' },}}
          noValidate autoComplete="off">
          <TextField id="outlined-basic" label="ville" variant="outlined" 
          InputLabelProps={{style:{fontStyle:'italic' ,color : '#A79C87',fontSize:'15px'} }}
          sx={style} 
          onChange={e => setVille(e.target.value)}/>
        </Box>

        <Box component="form" sx={{'& > :not(style)': { mt: 3,ml:2, width: '66ch',height:'5ch' },}}
          noValidate autoComplete="off">
          <TextField id="outlined-password-input" label="Mot de passe" 
          type={showPassword ? "text" : "password"}          
          autoComplete="current-password"
          InputLabelProps={{style:{fontStyle:'italic' ,color : '#A79C87',fontSize:'15px'} }}
          sx={style}
          onChange={handleChangeMessage}
          value={password}
          InputProps={{ // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                </IconButton>
              </InputAdornment>
            )
          }}/>
        </Box>

        <Box component="form" sx={{'& > :not(style)': { mt: 3,ml:2, width: '66ch',height:'5ch' },}}
          noValidate autoComplete="off">
          <TextField id="outlined-password-input" label="Confirmer mot de passe"
          type={showPasswordConf ? "text" : "password"}          
          autoComplete="current-password"
          InputLabelProps={{style:{fontStyle:'italic' ,color : '#A79C87',fontSize:'15px'} }}
          sx={style}
          FormHelperTextProps={{style:{color:'red'}}}
          helperText={password==password_confirmation?"":"Veuillez vérifier le mot de passe" }
          onChange={handleChangeMessageConf}
          value= {password_confirmation}
          InputProps={{ 
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickshowPasswordConf}
                  onMouseDown={handleMouseDownPasswordConf}
                >
                  {showPasswordConf ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                </IconButton>
              </InputAdornment>
            )
          }}
          />
        </Box>
        
            
        <div className="footer">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn3"
          >
            Annuler
          </button>
          <button type="submit" >Ajouter</button>

        </div>
        </form>
      </div>

    </div>
  );
}

export default ModalAddChef;
