import React, {useEffect, useState} from "react";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import users from "../../Services/users";
import {NotificationManager} from "react-notifications";
import auth from "../../Services/auth";
import {CustomModal} from "../../components/reusables/CustomModal";
import {modalConfig} from "../../config/modalConfig";
import {ChangePasswordModal} from "./ChangePasswordModal";

const initialUserData = {
    id: 0,
    nom: '',
    prenom: '',
    email: ''
}

const Profile = () => {
    const [userData, setUserData] = useState(initialUserData)
    const [showModal, setShowModal] = useState(false)
    const getUserData = async () => {
        return await users.detailAdmin({});
    }
    const updateUserData = async (credentials) => {
        return await users.updateUser(credentials);
    }
    const getUserDetails = () => {
        getUserData().then((response) => {
            if ('admin' in response) {
                setUserData({
                    ...userData,
                    nom: response.admin.nom,
                    prenom: response.admin.prenom,
                    email: response.admin.email,
                })
            }
        })
    }
    const resetData = () => {
        getUserDetails()
    }
    const logout = async () => {
        const response = await auth.logOutUser({});
        if (!('desc_info' in response)) {
            localStorage.clear()
            window.location.href = '/'
        } else {
            NotificationManager.error(response.message)
        }
    }
    const updateData = () => {
        const myCredentials = {
            nom: userData.nom,
            prenom: userData.prenom,
            email: userData.email
        }
        updateUserData(myCredentials).then(() => {
            NotificationManager.success('Vos données sont mises à jour avec succès!')
        })
    }

    const handleClose = () => {
        setShowModal(false)
    }
    useEffect(() => {
        getUserDetails()
    }, [])

    return (
        <div className="container" style={{'paddingBottom': 100}}>
            <div className="row formPage">
                <div className="card py-lg-3">
                    <h2>Profil</h2>
                    <div className="position-absolute right-10">
                        <Button variant="danger" onClick={logout}>Se déconnecter</Button>
                    </div>
                    <hr/>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <Form.Group className="mb-3">
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Control placeholder="Nom"
                                                  value={userData.nom}
                                                  onChange={(event) => {
                                                      setUserData({
                                                          ...userData,
                                                          nom: event.target.value
                                                      })
                                                  }}/>
                                </Form.Group>
                            </div>
                            <div className="col">
                                <Form.Group className="mb-3">
                                    <Form.Label>Prénom</Form.Label>
                                    <Form.Control placeholder="Prénom"
                                                  value={userData.prenom}
                                                  onChange={(event) => {
                                                      setUserData({
                                                          ...userData,
                                                          prenom: event.target.value
                                                      })
                                                  }}/>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Form.Group className="mb-3">
                                    <Form.Label>Date de naissance</Form.Label>
                                    <Form.Control type={'date'} disabled/>
                                </Form.Group>
                            </div>
                            <div className="col">
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control placeholder="Email"
                                                  value={userData.email}
                                                  onChange={(event) => {
                                                      setUserData({
                                                          ...userData,
                                                          email: event.target.value
                                                      })
                                                  }}/>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Form.Group className="mb-3">
                                    <Form.Label>Pays</Form.Label>
                                    <Form.Select disabled>
                                        <option>Veuillez sélectionner le pays</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col">
                                <Form.Group className="mb-3">
                                    <Form.Label>Ville</Form.Label>
                                    <Form.Select disabled>
                                        <option>Veuillez sélectionner la ville</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Form.Group className="mb-3">
                                    <Form.Label>Adresse</Form.Label>
                                    <Form.Control placeholder="Adresse" disabled/>
                                </Form.Group>
                            </div>
                            <div className="col">
                                <Form.Group className="mb-3">
                                    <Form.Label>Code</Form.Label>
                                    <Form.Control placeholder="Code" disabled/>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col">
                                <div className="mx-1">
                                    <Button variant="warning" onClick={() => {
                                        setShowModal(true)
                                    }}
                                    >Modifier le mot de passe</Button>
                                </div>
                            </div>
                            <div className="col d-flex justify-content-end">
                                <div className="mx-1">
                                    <Button variant="secondary" onClick={resetData}>
                                        Annuler
                                    </Button></div>
                                <div className="mx-1">
                                    <Button variant="success" onClick={updateData}
                                    >Enregistrer</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showModal && <ChangePasswordModal show={showModal}
                                                       handleClose={handleClose}/>}
                </div>
            </div>
        </div>
    )
}

export default Profile;
