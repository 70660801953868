import {env} from "../config/env";
const token = localStorage.getItem('access_token');
export const getBookingStatistics = async () => {
    return fetch(env.backendUrl + `getStatistiqueReservation`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization : ` Bearer ${token}`
        }
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(
                    `This is an HTTP error: The status is ${response.status}`
                );
            }
            return response.json();
        })
        .then((actualData) => {
            return (actualData)
        })
        .catch((err) => {
            console.log(err.message);
        });
}
export const getUsersStatistics = async () => {
    return fetch(env.backendUrl + `getStatistiqueUsers`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization : ` Bearer ${token}`
        }
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(
                    `This is an HTTP error: The status is ${response.status}`
                );
            }
            return response.json();
        })
        .then((actualData) => {
            return (actualData)
        })
        .catch((err) => {
            console.log(err.message);
        });
}
