import React, {useCallback, useEffect, useRef, useState} from 'react';
import 'react-notifications/lib/notifications.css';
import './App.css';
import ResponsiveAppBar from './components/assets/AppBar';
import {LoginRoutes} from './routes/loginRoutes';
import RoutesList from './routes/route';
import AppBarLogin from './components/assets/AppBarLogin';
import 'bootstrap/dist/css/bootstrap.min.css';
import {NotificationContainer} from 'react-notifications';
import {env} from "./config/env";

function App() {
    const [isConnected, setIsConnected] = useState(false);
    const getNewUserToken = async () => {
        try {
            const res = await fetch(env.backendUrl + "adminRefreshToken", {
                method: "POST",
                headers: {
                    token: localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                },
            });

            if (res.status === 200) {
                const data = await res.json();
                localStorage.setItem("access_token", data.token);
            } else {
                // New token didnt received.Remove the previous token and user
                localStorage.removeItem("access_token");
                window.location.href = "/";
            }
        } catch (err) {
            console.log(err);
        }
    };
    const intervalRef = useRef();
    const getToken = useCallback(() => {
        // Get new token if and only if existing token is available
        if (localStorage.getItem("access_token") != null) {
            getNewUserToken().then();
        }
    }, []);

// Trigger API to get a new token before token gets expired.
    useEffect(() => {
        const interval = setInterval(() => getToken(), 1000 * 600 * 1000); // 6 minutes interval as our token will expire after 7 minutes.
        intervalRef.current = interval;
        return () => clearInterval(interval);

    }, [getToken]);


    useEffect(() => {
        if (localStorage.getItem('access_token')) {
            setIsConnected(true)
        }
    }, []);

    return (
        <>
            { isConnected ?
                <>
                    <ResponsiveAppBar/>
                    <div className="content">
                        <RoutesList/>
                    </div>
                    <NotificationContainer/>
                </> :
                <>
                    <AppBarLogin />
                    <LoginRoutes />
                    </>
            }
        </>);
}


export default App;
