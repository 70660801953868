import React from 'react'
import BookingStatistics from "./BookingStatistics";
import UsersStatistics from "./UsersStatistics";

const Dashboard = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="card py-lg-3">
                    <h2>Dashboard</h2>
                    <hr />
                    <div className="d-flex justify-content-between">
                        <BookingStatistics />
                        <UsersStatistics />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Dashboard
