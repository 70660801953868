import React, {useEffect, useState} from 'react';
import {UsersStatusFilter} from "./UsersStatusFilter";
import {
    columnsData,
    optionsChiefStatusFilter,
    optionsClubStatusFilter,
    optionsUsersStatusFilter,
    usersConfig
} from "../../config/usersConfig";
import {CustomPagination} from "../../components/reusables/CustomPagination";
import {format} from "date-fns";
import Table from 'react-bootstrap/Table';
import {systemConfig} from "../../config/systemConfig";

export const ListingData = (props: {
    dataType: string,
    data: any[],
    paginationControl: any,
    handleDetails: () => void,
    handleCurrentPage: () => void,
    handleFilters: () => void
}) => {

    const [columns, setColumns] = useState([])
    const [canContinue, setCanContinue] = useState(false)
    const handleUserStatusFilterChange = (event) => {
        props.handleFilters(event.value)
    };
    useEffect(() => {
        columnsData.forEach((item) => {
            if (item.type === props.dataType) {
                setColumns(item.columns)
                setCanContinue(true);
            }
        })
    }, [props.dataType, props.data])
    return (
        <>
            {(canContinue) && <Table style={{paddingBottom: props.data.length < 5 ? 200 : 20}}>
                <thead>
                <tr>
                    {
                        columns.map((col, index) => {
                            return (
                                <th key={index}>
                                    {(col !== 'Status') ? col :
                                        <UsersStatusFilter
                                            handleUserStatusFilterChange={handleUserStatusFilterChange}
                                            userType={props.dataType}
                                        />}
                                </th>
                            )
                        })
                    }
                </tr>
                </thead>

                <tbody>
                {props.data && props.data.map((item) => (
                    <tr
                        key={item.id}
                        onClick={() => props.handleDetails(item)}
                        className={
                            (((props.dataType === usersConfig.usersType.chief)
                            || (props.dataType === usersConfig.usersType.chiefMedal)) &&
                            item.deleted_at) ? 'click-disabled' : ''} >
                        <td>{item.nom}</td>
                        <td>{item.prenom}</td>
                        {(props.dataType === usersConfig.usersType.chief) && <>
                            {
                                optionsUsersStatusFilter.map((opt) => {
                                    return (
                                        (opt.value === item.statut_compte) ?
                                            <td
                                                style={{color: opt.color}}>{opt.label}</td> : ''
                                    )
                                })}
                            <td/>
                        </>
                        }
                        {(props.dataType === usersConfig.usersType.chiefMedal) &&
                        <>
                            <td>{item.statut_chef}</td>
                            {
                                optionsChiefStatusFilter.map((opt, index) => {
                                    return (
                                        (opt.value === item.statut_compte) ?
                                            <td key={index}
                                                style={{color: opt.color}}>{opt.label}</td> : ''
                                    )
                                })}
                        </>
                        }
                        {(props.dataType === usersConfig.usersType.clients) &&
                        <>
                        <td/>
                        <td>{format(new Date(item.created_at), 'dd/MM/yyyy')}</td>
                        <td style={{color: (item.vp_token !== null)
                                ? systemConfig.color.green
                                : systemConfig.color.red}}>{(item.vp_token !== null)? 'Activé' : 'Désactivé'}</td>
                            </>
                        }
                        </tr>
                            ))}
                    </tbody>
                    <tfoot>
                    <CustomPagination currentPage={props.paginationControl.currentPage}
                    countPage={props.paginationControl.countPage}
                    handleCurrentPage={props.handleCurrentPage}
                    />
                    </tfoot>
                    </Table>}
                </>
                );
                }

