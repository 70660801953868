import React from "react";
import type {EventParticipation} from "../../utils/types/EventsTypes";
import {InfoLine} from "../../components/reusables/InfoLine";
import {formatDate} from "../../utils/functions/formatDate";

export const ModalEventContent = (props: { data: EventParticipation }) => {
    return (
        <>
            <div className="card mb-2">
                <div className="card-header">Participant</div>
                <div className="card-body">
                    <InfoLine label={"Nom et Prénom"} value={props.data.nom + " " + props.data.prenom}/>
                    <InfoLine label={"Email"} value={props.data.email}/>
                    <InfoLine label={"Téléphone"} value={props.data.phone}/>

                    {(props.data.votre_demande) && <> {
                        (props.data.votre_demande.split('=')[0] && props.data.votre_demande.split('=')[0] !== '') &&
                        <InfoLine label={"Adresse"} value={props.data.votre_demande.split('=')[0].replace('date', '')}/>
                    }
                        {
                            (props.data.votre_demande.split('=')[1] && props.data.votre_demande.split('=')[1] !== '') &&
                            <InfoLine label={"Date"}
                                      value={formatDate(props.data.votre_demande.split('=')[1].replace('convives', ''))}/>
                        }
                        {
                            (props.data.votre_demande.split('=')[2] && props.data.votre_demande.split('=')[2] !== '') &&
                            <InfoLine label={"Nombre de convives"}
                                      value={props.data.votre_demande.split('=')[2].replace('adresse', '')}/>
                        }
                        {
                            (props.data.votre_demande.split('=')[3] && props.data.votre_demande.split('=')[3] !== '') &&
                            <InfoLine label={"Description"} value={props.data.votre_demande.split('=')[3]}/>
                        }
                    </>
                    }
                </div>
            </div>
        </>
    );
}
