import {env} from "../config/env";

const token = localStorage.getItem('access_token');
export async function videMenuNotif(credentials) {
    return fetch(env.backendUrl + 'videMenuNotif', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: ` Bearer ${token}`
        },
        body: JSON.stringify(credentials)
    })
        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        })
        .catch(error => {
            console.error('There was an error!', error);
            return Promise.reject(error);
        });
}
