import React, {useEffect, useState} from "react";
import {systemConfig} from "../../config/systemConfig";
import BarChart from "./barChart";
import {getBookingStatistics} from "../../Services/dashboard";


const initialChartData = {
    labels: [],
    dataSetsLabel: 'Ventes hebdomadaires',
    dataSetsData: []
}
const initialStatistics = {
    total: 0,
    turnover: 0
}
const BookingStatistics = () => {
    const [data, setData] = useState([])
    const [barChartData, setBarChartData] = React.useState(initialChartData);
    const [globalStatistics, setGlobalStatistics] = React.useState(initialStatistics);
    const getData = () => {
        return getBookingStatistics();
    }
    useEffect(() => {
        getData().then((response) => {
            setGlobalStatistics({
                ...globalStatistics,
                total: response.count_all_reservations,
                turnover: response.chiffre_affaire_totale
            })
            const arr = []
            if (response && response.code && response.code === 'succes') {
                Object.keys(response.week_info).forEach(key => arr.push({date: key, data: response.week_info[key]}))
                setData(arr)
            }
        });

    }, [])
    useEffect(() => {
        const myLabels = []
        const myData = []
        data.forEach((item) => {
            myLabels.push(item.date)
            myData.push(item.data.count_reservations_en_cours)
        })
        setBarChartData({
            ...barChartData,
            labels: myLabels,
            dataSetsData: myData
        });
    }, [data])
    return (
        <div className="col align-self-stretch">
            <div className="card border-0 p-md-3">
                <div className="row justify-content-between m-lg-4">
                    <div className="col-6">
                        Total <span
                        style={{color: systemConfig.color.orange}}>{globalStatistics.total}</span>
                    </div>
                    <div className="col-6 text-end">
                        chiffre d'affaire <span
                        style={{color: systemConfig.color.green}}>{globalStatistics.turnover} €</span>
                    </div>
                </div>
                <BarChart data={barChartData} />
            </div>
        </div>
    )
}

export default BookingStatistics;
