import React, {useEffect, useState} from "react";
import '../../App.css';
import {CustomPagination} from "../../components/reusables/CustomPagination";
import {systemConfig} from '../../config/systemConfig';
import type {MenuDetailsResponse, MenusResponse} from "../../utils/types/MenusTypes";
import {CustomModal} from "../../components/reusables/CustomModal";
import {modalConfig} from "../../config/modalConfig";
import {env} from "../../config/env";
import menusServices, {refuseMenu} from "../../Services/menusServices";
import {NotificationManager} from 'react-notifications';
import Table from 'react-bootstrap/Table';
import {statusConfig, statusControls} from "../../config/statusConfig";
import {StatusFilter} from "../../components/reusables/StatusFilter";
import {formatDate} from "../../utils/functions/formatDate";
import {videMenuNotif} from "../../Services/notifs";


const Menu = () => {
    const initialPaginationState = {
        currentPage: 1,
        countPage: 1,
        handleCurrentPage: function (newPage: number) {
            getMenusList(newPage + 1).then();
        }
    }
    const [paginationControl, setPaginationControl] = React.useState(initialPaginationState);
    const [menus, setMenus] = useState([]);
    const token = localStorage.getItem('access_token');
    const [show, setShow] = useState(false);
    const [myModalData, setMyModalData] = useState();
    const [menuDetails, setMenuDetails] = useState()

    async function getMenuDetails(menuId: number) {
        return fetch(env.backendUrl + 'getMenuDetails/' + menuId, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => response.json())
            .then((data: MenuDetailsResponse) => {
                return (data.menu)
            });
    }

    const showModal = (record) => {
        setMyModalData(record)
        getMenuDetails(record.id).then((response) => {
            setMenuDetails(response);
            setShow(true);
        })
    };


    const getMenusList = (page?: number, status?: string) => {
        let path = env.backendUrl + "getAllMenus_admin/" + systemConfig.rowsPerPage;
        if (page) { //todo: this should be optimized
            path += '?page=' + page;
        }
        if (status) { //todo: this should be optimized
            path += '?status_dev=' + status;
        }
        return fetch(path,
            {
                headers: {
                    'content-type': 'application/json',
                    Authorization: ` Bearer ${token}`
                }
            })
            .then((response) => response.json())
            .then((actualData: MenusResponse) => {
                    setMenus(actualData.data);

                    setPaginationControl({
                        ...paginationControl,
                        countPage: actualData.total > systemConfig.rowsPerPage ?
                            Math.floor(actualData.total / systemConfig.rowsPerPage) + 1 :
                            Math.floor(actualData.total / systemConfig.rowsPerPage)
                        ,
                    });
                },
            );

    }


    useEffect(() => {
        getMenusList().then();
    }, [setMenus]);

    function handleStatusFilterChange(event) {
        getMenusList(null, event.value).then();
    }

    const handleClose = () => {
        setShow(false)
    }
    const handleStatus = (data) => {
        let credentials = {
            "menu_id": data.menu_id
        }
        if (data.status) {
            credentials = {
                "menu_id": data.menu_id,
                "status": data.status
            }
        }
        menusServices.validMenu(credentials).then(() => {
            getMenusList().then()
            handleClose()
            NotificationManager.success('Votre menu est bien mis à jour!', 'Action réussie');
        });
    }
    const refuseMenuHandler = () => {
        let credentials = {
            'menu_id': menuDetails.id,
            'status': 'refus'
        }
        refuseMenu(credentials).then(() => {
            getMenusList().then()
            handleClose()
            NotificationManager.success('Votre menu est bien mis à jour!', 'Action réussie');
        });
    }
    const optionsMenusFilter = [
        {
            value: null,
            label: 'Status',
            color: null
        },
        {
            value: statusConfig.statusValues.accepted,
            label: statusConfig.statusLabels.accepted,
            color: statusConfig.statusColors.accepted
        },
        {
            value: statusConfig.statusValues.inProgress,
            label: statusConfig.statusLabels.inProgress,
            color: statusConfig.statusColors.inProgress
        },
        {
            value: statusConfig.statusValues.rejected,
            label: statusConfig.statusLabels.rejected,
            color: statusConfig.statusColors.rejected
        },
        {
            value: statusConfig.statusValues.draft,
            label: statusConfig.statusLabels.draft,
            color: statusConfig.statusColors.draft
        },
    ]
    return (
        <div className="container">
            <div className="row">
                <div className="card py-lg-3">
                    <h2>Menu</h2>
                    <hr/>
                    <Table style={{paddingBottom: menus.length < 5 ? 200 : 20}}>
                            <thead>
                            <tr>
                                <th>Intitulé</th>
                                <th>Nom</th>
                                <th>Prénom</th>
                                <th>Créé le</th>
                                <th>
                                    <StatusFilter
                                        optionsFilter={optionsMenusFilter}
                                        handleStatusFilterChange={handleStatusFilterChange}/>
                                </th>
                            </tr>
                            </thead>

                            <tbody>
                            {menus.map((menu) => (
                                <tr
                                    key={menu.id}
                                    onClick={() => showModal(menu)}>
                                    <td>{menu.name}</td>
                                    <td>{menu.nom}</td>
                                    <td>{menu.prenom}</td>
                                    <td>{formatDate(menu.created_at)}</td>
                                    {
                                        statusControls.map((x) => {
                                            if (x.status === menu.status_dev) {
                                                return (
                                                    <td
                                                        style={{color: x.color}}>{x.label}</td>
                                                )
                                            }
                                        })
                                    }
                                </tr>
                            ))}

                            </tbody>
                            <tfoot>
                            <CustomPagination currentPage={paginationControl.currentPage}
                                              countPage={paginationControl.countPage}
                                              handleCurrentPage={paginationControl.handleCurrentPage}
                            />
                            </tfoot>
                        </Table>
                </div>
            </div>
            {show && <CustomModal show={show}
                                  handleClose={handleClose}
                                  dataType={modalConfig.dataType.menuType}
                                  data={menuDetails}
                                  details={myModalData}
                                  handleStatus={handleStatus}
                                  refuseMenu={refuseMenuHandler}

            />}
        </div>

    );
}

export default Menu
