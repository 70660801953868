import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Icon, InputLabel, Link, Typography } from '@material-ui/core';
import '../../Views/AuthViews/login.css';
import { useLocation } from 'react-router-dom';


  const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '32px',
      gap: '32px',
      width: '100%',
      height: "70%",
      backgroundColor: "white"
    },
  
  
   
    form: {
      width: '100%',
    },
    submit: {
      width: '311px',
      backgroundColor:' #EC6730',
      color :' #FFFFFF',
      height:'61.73px',
      fontSize:'17px',
      fontFamily: 'OpneSans',
      textAlign: 'center',
      letterSpacing: '0.08em',
      borderRadius:'2px',
      top:'20px',
      gap:'10px',
    },
    
  icon:{ 
    width: '44px',
    height:'44px',left: '1.5px',
    top:'1px',
    color:' #A79C87',
    border: '2px', solid :'#A79C87',
  },
  
  label :{
    width:'311px',
    height:'54px',
    fontFamily: 'OpenSans',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '27px',
    letterSpacing:'0em',
    textAlign: 'center',
    color :' #817F7C',
    textTransform:'uppercase' ,
    letterSpacing: '0.08em',
  },
  InputLabel:{
    wheight:'00px',
    width:'311px',
    height:'60px',
    fontFamily: 'OpenSans',
    fontStyle:'italic',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'center',
    color :'#A79C87',
  },
  icon:{ 
    width: '44px',
    height:'44px',left: '1.5px',
    top:'1px',
    color:' #A79C87',
  },
  email:{ 
    fontFamily: 'Open Sans',
    fontSize: '15px',
   // font-weight: 400;
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'center',
    color:'#EC6730',
    textTransform:'lowercase' ,
    height:' 20px',
    width: '311px',
    top: '146px',
    
  },
  }));

export default function Confirmation(props)
 {
    const classes = useStyles();
    //const user = JSON.parse(localStorage.getItem('email'));

    const location = useLocation();

    const handleSubmit = async e => {
      e.preventDefault();
      window.location.href = "/";
    }
    useEffect(() => {
      console.log(location);
    }, [])

  
    return (       

     
      <Grid
      container
      spacing={0}
      align="center"
      justify="center"
      direction="column"
    >
      <Grid item >
        <Grid item className={classes.paper}>
            <CheckCircleOutlineIcon  fontSize="large" className={classes.icon}/> 
            <InputLabel  className={classes.label}>
           Vous avez reçu un email à l'adresse :
           <Typography className={classes.email}>{location.state} </Typography>
           </InputLabel> 
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <InputLabel justifyContent='center'>
            </InputLabel>
              <Typography className={classes.InputLabel}>
            
              Cliquez sur le lien dans l e-mail reçu.<br></br>
              Si vous n avez reçu aucun mail, veuillez vérifier<br></br>
              vos courriers indésirables.  
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
                size="large"

              >
                {'merci'.toUpperCase()}
              </Button>
            </form>
          </Grid>
        </Grid>
      </Grid>
    );
  }



