export const PATHS = {
    Home: '/home',
    Login: '/',
    Confirmation: '/confirmation',
    Reset: '/reset',
    dash:'/dashboard',
    Utilisateur: '/utilisateurs',
    Menu: '/menus',
    Evenement: '/evenements',
    Profil:'/profil',
    Supression:'/supression'
  };
  