import {env} from "../config/env";

const token = localStorage.getItem('access_token');

async function loginUser(credentials) {
    return fetch(env.backendUrl + 'adminLogin', {
      method: 'POST',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
}

async function logOutUser(credentials) {
  return fetch(env.backendUrl + 'logoutAdmin', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization : ` Bearer ${token}`
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}


  async function Reset(credentials) {

    return fetch(env.backendUrl + 'forgetPassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
      .then((actualData) => {
        console.log(actualData);
      return actualData ;
      },
      );

  }

export default{loginUser,Reset,logOutUser}
