import {env} from "../config/env";


const token = localStorage.getItem('access_token');

async function addChef(credentials) {
    return fetch(env.backendUrl + 'addChef', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization : ` Bearer ${token}`
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
}

async function detailAdmin(credentials) {
  return fetch(env.backendUrl + 'adminDetails', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization : ` Bearer ${token}`
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

async function updateUser(credentials) {
  return fetch(env.backendUrl + 'updateAdmin', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization : ` Bearer ${token}`
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

async function updatePassword(credentials) {
  return fetch(env.backendUrl + 'updateAdmin', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization : ` Bearer ${token}`
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

async function adminDetails(credentials) {
  return fetch(env.backendUrl + 'adminDetails', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization : ` Bearer ${token}`
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}
export default{addChef,detailAdmin,updateUser,adminDetails,updatePassword}

export async function changeStatus(credentials) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization : ` Bearer ${token}`
    },
    body: JSON.stringify(credentials)
  };
  await fetch(env.backendUrl + 'changeStatusAccount', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
        return Promise.reject(error);
      });
}
export async function deleteUser(credentials, path) {
  await fetch(env.backendUrl + path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization : ` Bearer ${token}`
    },
    body: JSON.stringify(credentials)
  })
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
        return Promise.reject(error);
      });
}
