import React from "react";
import Select from "react-select";

type optionsFilterType = {
    value: string | null,
    label: string,
    color: string | null,
}
type statusFilterProps = {
    handleStatusFilterChange: () => void,
    optionsFilter: optionsFilterType[]
}
export const StatusFilter: React.FunctionComponent<any>
    = (props: statusFilterProps) => {

    // Invoke when user click to request another page.
    const handleStatusFilterChange = (event) => {
        props.handleStatusFilterChange(event);
    };
    const formatOptionLabel = ({value, label, color}) => (
        <div style={{display: 'flex', verticalAlign: 'middle'}}>
            {(value !== null) && <div
                style={{
                    width: 20,
                    height: 20,
                    borderRadius: 50,
                    marginRight: 10,
                    marginTop: 5,
                    backgroundColor: color
                }}></div>}
            {label}
        </div>
    );
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "transparent",
            // match with the menu
            borderRadius: 0,
            // Overwrittes the different states of border
            borderColor: 'transparent',
            // Removes weird border around container
            boxShadow: null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? "#f5f5f5" : "#fefefe"
            },
            minWidth: 150,
            paddingLeft: 0
        }),
        menu: base => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        })
    };
    return (
        <Select
            options={props.optionsFilter}
            defaultValue={props.optionsFilter[0]}
            formatOptionLabel={formatOptionLabel}
            onChange={handleStatusFilterChange}
            styles={customStyles}
            isSearchable={false}
        />
    )
}
