import React, {useEffect, useState} from 'react'
import ModalAddChef from '../../components/modal/User/modalAddChef';
import {onMessageListener} from 'src/firebaseInit';
import {systemConfig} from "../../config/systemConfig";
import {usersConfig} from "../../config/usersConfig";
import {UsersFilter} from "./UsersFilter";
import {ListingData} from "./ListingData";
import {NotificationManager} from "react-notifications";
import {CustomModal} from "../../components/reusables/CustomModal";
import {modalConfig} from "../../config/modalConfig";
import {changeStatus, deleteChef, deleteUser} from "../../Services/users";
import {env} from "../../config/env";
import SearchInput from "../../components/reusables/SearchInput";
import {videMenuNotif} from "../../Services/notifs";

const initialFilters = {
    lookFor: usersConfig.usersType.chief,
    status: usersConfig.chiefStatus.chief,
}

const initialPaginationState = {
    currentPage: 1,
    countPage: 1,
}
const User = () => {
    const [activeFilters, setActiveFilters] = React.useState(initialFilters);
    const [paginationControl, setPaginationControl] = React.useState(initialPaginationState);
    const [data, setData] = React.useState('');
    const [term, setTerm] = React.useState('');
    const [show, setShow] = useState(false);
    const [userDetails, setUserDetails] = useState(false);

    const handleCurrentPage = (newPage: number) => {
        fetchData(newPage + 1).then();
    }
    const handleAccountFilter = (filter: string | number) => {
        console.log('filter == ', filter)
        if(activeFilters.lookFor === usersConfig.usersType.clients) {
            // todo: we should to add the good filter here
            fetchData(null, null).then();
        } else {
            fetchData(null, filter).then();
        }

    }
    const handleClose = () => {
        setShow(false)
    }
    const handleStatus = (event) => {
        changeStatus(event).then(() => {
            fetchData().then()
            handleClose()
            NotificationManager.success('Vos données sont bien mises à jours!', 'Succès')
        }, () => {
            handleClose()
            NotificationManager.error('Une erreur est survenue!', 'Erreur')
        })
    }
    const handleDetails = (event) => {
        console.log('event === ', event)
        if ((((activeFilters.lookFor === usersConfig.usersType.chief)
            || (activeFilters.lookFor === usersConfig.usersType.chiefMedal)) &&
            event.deleted_at)) {

        } else {
            setUserDetails(event)
            setShow(true)
        }
    }

    const childToParentBoolean = (childdata) => {
        if (childdata === true) {
            NotificationManager.success('Chef ajouté avec succès', 'Action réussie');
        } else {
            NotificationManager.error('Veuillez réessayer', 'Action non réussie');
        }
    }

    const handleDelete = (userId, userType) => {
        console.log('in custom modal, id to delete is ', userId)
        let myPth = '';
        let params = {};
        if((userType === usersConfig.usersType.chiefMedal) || (userType === usersConfig.usersType.chief)) {
            myPth += 'adminDeleteChef';
            params = {chef_id: userId}
        } else if(userType === usersConfig.usersType.clients) {
            myPth += 'adminDeleteClient';
            params = {client_id: userId}
        }
        if(myPth !== '') {
            deleteUser(params, myPth).then(() => {
                handleClose()
                fetchData().then()
                NotificationManager.success('Vos données sont bien mises à jours!', 'Succès')
            }, () => {
                handleClose()
                NotificationManager.error('Une erreur est survenue!', 'Erreur')
            })
        }
    }

    const [modalOpen, setModalOpen] = React.useState(false);

    const showModal = () => {
        setModalOpen(true);
    };
    const [show_ch_p] = React.useState(false);


    const token = localStorage.getItem('access_token');

    async function fetchData(pageNumber ?: number,
                             accountStatus?: string) {
        // todo: api should be in an external file
        let path = env.backendUrl;
        if (
            activeFilters.lookFor === usersConfig.usersType.chief
            || activeFilters.lookFor === usersConfig.usersType.chiefMedal
        ) {
            path += 'getChefs/' + systemConfig.rowsPerPage + '?&sort=desc';
            if (activeFilters.status) {
                path += '&statut_chef=' + activeFilters.status;
            }
        }
        if (activeFilters.lookFor === usersConfig.usersType.clients) {
            path += 'getClients/' + systemConfig.rowsPerPage + '?&sort=desc';
        }
        if (pageNumber) {
            path += '&page=' + pageNumber
        }
        if (accountStatus) {
            path += '&statut_compte=' + accountStatus
        }
        if (term) {
            path += '&search_query=' + term
        }
        fetch(path,
            {
                headers: {
                    'content-type': 'application/json',
                    Authorization: ` Bearer ${token}`
                }
            })
            .then(response => {
                return response.json()
            })
            .then(actualData => {
                setData(actualData.data)
                setPaginationControl({
                    ...paginationControl,
                    countPage: actualData.total > systemConfig.rowsPerPage ?
                        Math.floor(actualData.total / systemConfig.rowsPerPage) + 1 :
                        Math.floor(actualData.total / systemConfig.rowsPerPage)
                    ,
                    currentPage: actualData.current_page
                })
            })
    }


    useEffect(() => {
        fetchData().then();
    }, [activeFilters])

    function handleUserFilterChange(event) {
        console.log('event == ', event)
        setActiveFilters({
            ...activeFilters,
            lookFor: event.value,
            status: event.status
        });
    }
    const handleSearchChange = (term) => {
        if(term.length === 1) {
            NotificationManager.info('Vous devez taper au moins 3 caractères');
        }
        if(term.length > 3) {
            setTerm(term)
        } else {
            setTerm('')
        }
    }
    useEffect(() => {
        fetchData().then()
    }, [term])

    onMessageListener()
        .then((payload) => {
            NotificationManager.success(payload.notification.body, payload.notification.title);
        })
        .catch((err) => console.log("failed: ", err));

    return (
        <div className="container">
            <div className="row">
                <div className="card py-lg-3">
                    <h2>Utilisateurs</h2>
                    {show_ch_p &&
                    <div className="position-absolute right-10">
                        <button
                            className="btn btn-primary"
                            onClick={() => showModal()}>Ajouter un Chef
                        </button>
                    </div>
                    }

                    <hr/>
                    <div className="d-flex justify-content-between mb-lg-5">
                        <div className="col-4">
                            <UsersFilter
                                handleUserFilterChange={handleUserFilterChange}/>
                        </div>
                        <div className="col-4">
                            <SearchInput placeholder={'Chercher un nom..'}
                                         handleChange={handleSearchChange}/>
                        </div>
                    </div>
                    <div>
                        <ListingData dataType={activeFilters.lookFor}
                                     data={data}
                                     paginationControl={paginationControl}
                                     handleDetails={handleDetails}
                                     handleCurrentPage={handleCurrentPage}
                                     handleFilters={handleAccountFilter} />

                        {show && <CustomModal show={show}
                                              handleClose={handleClose}
                                              dataType={modalConfig.dataType.userType}
                                              data={userDetails}
                                              userType={activeFilters.lookFor}
                                              handleStatus={handleStatus}
                                              handleDelete={handleDelete} /> }

                        {modalOpen &&
                        <ModalAddChef childToParentBoolean={childToParentBoolean} setOpenModal={setModalOpen}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default User

