import React, {useEffect, useState} from 'react';
import {statusControls} from "../../config/statusConfig";

export const PinStatus = (props: {
    status: string,
}) => {
    const [background, setBackground] = useState('')
    const [goodLabel, setGoodLabel] = useState('')

    useEffect(() => {
            statusControls.forEach((x) => {
                if (x.menuStatus === props.status) {
                    setBackground (x.menuColor )
                    setGoodLabel(x.menuLabel)
                }
            })
    }, [props.status])
    return (
        <button className="btn position-absolute right-10 mt-4  text-white"
                style={{'backgroundColor': background}}

        >{goodLabel} </button>
    );
}

