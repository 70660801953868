import React, {useEffect, useState} from "react";
import Table from 'react-bootstrap/Table';
import '../../App.css';
import swal from "sweetalert";
import {env} from "../../config/env";
import {CustomPagination} from "../../components/reusables/CustomPagination";
import {systemConfig} from "../../config/systemConfig";
import {statusConfig, statusControls} from "../../config/statusConfig";
import {StatusFilter} from "../../components/reusables/StatusFilter";
import {CustomModal} from "../../components/reusables/CustomModal";
import {modalConfig} from "../../config/modalConfig";
import {NotificationManager} from "react-notifications";
import eventService from "../../Services/eventService";
import {formatDate} from "../../utils/functions/formatDate";

function Event() {
    const initialPaginationState = {
        currentPage: 1,
        countPage: 1,
        handleCurrentPage: function (newPage: number) {
            getEventList(newPage + 1).then();
        }
    }
    const [paginationControl, setPaginationControl] = React.useState(initialPaginationState);
    const [event, setEvent] = useState([]);
    const [show, setShow] = useState(false);
    const [modaldata, setmodaldata] = useState([]);
    const token = localStorage.getItem('access_token');

    const getEventList =  (page?: number, status?: string) => {
        let myPath = env.backendUrl + "getListParticipations/"+systemConfig.rowsPerPage;
        if (page) { //todo: this should be optimized
            myPath += '?page=' + page;
        }
        if (status) { //todo: this should be optimized
            myPath += '?status=' + status;
        }
        fetch(myPath,
            {
                headers: {
                    'content-type': 'application/json',
                    Authorization: ` Bearer ${token}`
                }
            })
            .then((response) => response.json())
            .then((actualData) => {
                    console.log(actualData.data);
                    setEvent(actualData.data);
                    setPaginationControl({
                        ...paginationControl,
                        countPage: actualData.total > systemConfig.rowsPerPage ?
                            Math.floor(actualData.total / systemConfig.rowsPerPage) + 1 :
                            Math.floor(actualData.total / systemConfig.rowsPerPage)
                        ,
                    });
                },
            );


    }

    async function videMenuNotif(credentials) {
        return fetch(env.backendUrl + 'videMenuNotif', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: ` Bearer ${token}`
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json())
            .then((actualData) => {
                    console.log(actualData);
                    return actualData;
                },
            );
    }

    useEffect(() => {
        getEventList();
    }, [setEvent]);

    /*******    modal const & functions    ******/
    const showModal = (record) => {
        console.log('record == ', record);
        setmodaldata(record);
        setShow(true)
    };
    const handleClose = () => {
        setShow(false)
    }
    const handleStatus = (data) => {
        let credentials = {
            "participation_id": data.participation_id,
            "status": data.status,
        }
        eventService.chageStatut(credentials).then(() => {
            getEventList()
            handleClose()
            NotificationManager.success('Votre menu est bien mis à jour!', 'Action réussie');
        });
    }
    /************** end ****************************************************************************/

    const optionsFilter = [
        {
            value: null,
            label: 'Status',
            color: null
        },
        {
            value: statusConfig.statusValues.treaty,
            label: statusConfig.statusLabels.treaty,
            color: statusConfig.statusColors.treaty
        },
        {
            value: statusConfig.statusValues.waiting,
            label: statusConfig.statusLabels.waiting,
            color: statusConfig.statusColors.waiting
        }
    ]

    function handleStatusFilterChange(event) {
        getEventList(null, event.value);
    }

    return (
        <div className="container">
            <div className="row">
                <div className="card py-lg-3">
                    <h2>Évènements</h2>
                    <hr/>
                    <Table size="small" aria-label="customized table">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Nom</th>
                                <th>
                                    <StatusFilter optionsFilter={optionsFilter}
                                                  handleStatusFilterChange={handleStatusFilterChange}
                                    />
                                </th>
                                <th align="left">Envoyé le</th>
                            </tr>
                        </thead>
                        <tbody>
                        {event.map((event) => (
                            <tr
                                key={event.id}
                                onClick={() => showModal(event)} >
                                <td>{event.evenement.title}</td>
                                <td>{event.email}</td>
                                {
                                    statusControls.map((x) => {
                                        if (x.status === event.status) {
                                            return (<td style={{color: x.color}}>{x.label}</td>)
                                        }
                                    })
                                }
                                <td>{formatDate(event.created_at)}</td>

                            </tr>
                        ))}
                        </tbody>

                        <tfoot>
                        <CustomPagination currentPage={paginationControl.currentPage}
                                          countPage={paginationControl.countPage}
                                          handleCurrentPage={paginationControl.handleCurrentPage}
                        />
                        </tfoot>
                    </Table>
                    {show && <CustomModal show={show}
                                          handleClose={handleClose}
                                          dataType={modalConfig.dataType.eventType}
                                          data={modaldata}
                                          handleStatus={handleStatus}

                    />}

                </div>
            </div>
        </div>
    )
}

export default Event;
