import React from "react";
import type {MenuDetails} from "../../utils/types/MenusTypes";

export const ModalMenuContent = (props: { data: MenuDetails }) => {
    return (
        <>
            {props.data.photo !== null &&
            <div className="text-center mb-5">
                <img src={props.data.photo}
                     className='img-fluid shadow-4 rounded'
                     alt={props.data.name}
                />
            </div>
            }
            <>
                {(props.data.description !== null) && <div className="card mb-2">
                    <div className="card-header"><h5>Description du menu</h5></div>
                    <div className="card-body">{props.data.description}</div>
                </div>}
                {props.data.planTypes.map((item, index) => {
                  return ( <div className="card mb-2" key={index}>
                      <div className="card-header"><h5>{item.type}</h5></div>
                      <div className="card-body">
                          {item.name_plat.map((t, index) => {
                              let res = t;
                              if(index < item.name_plat.length - 1) {
                                  res += ', '
                              }
                              return res
                          })}
                      </div>
                  </div>)
                })}
                {(props.data.commentaire !== null) && <div className="card mb-2">
                    <div className="card-header"><h5>Commentaire</h5></div>
                    <div className="card-body">{props.data.commentaire}</div>
                </div>
                }
                {(props.data.min_price !== null) &&
                <h4 className="p-3 mb-2 bg-light">
                    <span>Tarif </span>
                    <span className="text-secondary"><strong>{props.data.min_price} €/convive</strong></span>
                </h4>
                }
                {/*todo: add that if there many comments to display*/}
                {/*<CollapsableSection buttonTitle={'Voir Plus de commentaires'}*/}
                {/*                    content={'hello'}*/}
                {/*                    dataType={modalConfig.dataType.menuType}*/}
                {/*/>*/}
            </>
        </>
    );
}
