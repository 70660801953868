import React from "react";
import Select from "react-select";
import {
    optionsChiefStatusFilter,
    optionsClubStatusFilter,
    optionsUsersStatusFilter,
    usersConfig
} from "../../config/usersConfig";
import {systemConfig} from "../../config/systemConfig";

export const UsersStatusFilter: React.FunctionComponent<any>
    = (props: { handleUserStatusFilterChange: () => void, userType: string }) => {

    // Invoke when user click to request another page.
    const handleUserFilterChange = (event) => {
        props.handleUserStatusFilterChange(event);
    };
    const formatOptionLabel = ({value, label, color}) => (
        <div style={{display: 'flex', verticalAlign: 'middle'}}>
            {(value !== null) && <div
                style={{
                    width: 20,
                    height: 20,
                    borderRadius: 50,
                    marginRight: 10,
                    marginTop: 5,
                    backgroundColor: color
                }}/> }{label}
        </div>
    );
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "transparent",
            // match with the menu
            borderRadius: 0,
            // Overwrittes the different states of border
            borderColor: 'transparent',
            // Removes weird border around container
            boxShadow: null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? "#f5f5f5" : "#fefefe"
            },
            minWidth: 150,
            paddingLeft: 0
        }),
        menu: base => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        })
    };
    return (
        <Select
            options={
                props.userType === usersConfig.usersType.chief ? optionsUsersStatusFilter :
                    props.userType === usersConfig.usersType.chiefMedal ? optionsChiefStatusFilter :
                        optionsClubStatusFilter
            }
            defaultValue={
                props.userType === usersConfig.usersType.chief ? optionsUsersStatusFilter[0] :
                    props.userType === usersConfig.usersType.chiefMedal ? optionsChiefStatusFilter [0] :
                        optionsClubStatusFilter[0]
            }
            formatOptionLabel={formatOptionLabel}
            onChange={handleUserFilterChange}
            styles={customStyles}
            isSearchable={false}
        />
    )
}
