export const systemConfig = {
    rowsPerPage: 10,
    color: {
        green: '#417623',
        orange: '#f67d0c',
        red: '#F03738',
        gray: '#adacac',
        blueGray: '#00677D'
    },
    dataType: {
        menuType: 'menu',
        userType: 'user',
        eventType: 'event'
    },
    dateFormat: 'en-GB'
}
