import React from 'react';
import Star from "../../images/Star.png";

export const Rating = (props: {
    rating: number,
}) => {
    return (
        <div className="mb-3">
            {props.rating} <img src={Star} alt="star" />
        </div>
    );
}

